const HELP = '/help'
const EMBED = '/embed'
const ACCOUNT = '/account'
const CONTACT = '/contact'
const CHECKOUT = '/checkout'
const PURCHASE = '/purchase'
const BUSINESS_TOOL = '/business-tool'

export const ROUTE_PATH = {
  ROOT: '/',
  ENTRY: {
    INDEX: '/',
    ABOUT: '/about',
    CREATORS: '/creators',
    PRODUCT: '/product',
    CONTACT: { INDEX: CONTACT, COMPLETED: `${CONTACT}/completed` },
  },
  PLANS: '/plans',
  CAREERS: '/careers',
  VERIFY_EMAIL: '/verify-email',
  DOWNLOAD: '/download',
  AUTH: '/auth',
  RESET_PASSWORD: '/account/reset-password',
  PURCHASE: {
    INDEX: PURCHASE,
    BOOST: `${PURCHASE}/boost`,
    CHAT: `${PURCHASE}/chat`,
  },
  CHECKOUT: {
    INDEX: CHECKOUT,
    PURCHASE: `${CHECKOUT}/purchase`,
    RESUME: `${CHECKOUT}/resume`,
  },
  ACCOUNT: {
    INDEX: ACCOUNT,
    PAYMENT_METHOD: `${ACCOUNT}/payment-method`,
    SUBSCRIPTION: `${ACCOUNT}/subscription`,
    CURRENT_PLAN: `${ACCOUNT}/current-plan`,
    RESET_PASSWORD_SUCCESS: `${ACCOUNT}/reset-password/success`,
    MEMBERSHIP: `${ACCOUNT}/membership`,
  },
  BUSINESS_TOOL: {
    INDEX: BUSINESS_TOOL,
    MEMBERSHIP: `${BUSINESS_TOOL}/membership`,
    INSIGHT: `${BUSINESS_TOOL}/insights`,
    BOOST: `${BUSINESS_TOOL}/boost-pals`,
    MANAGE_BOOST: `${BUSINESS_TOOL}/manage-boost`,
  },
  TERMS: {
    PRIVACY_POLICY: '/privacy-policy',
    PALUP_RULES: '/palup-rules',
    USER_TERMS_OF_SERVICE: '/user-terms-of-service',
    ADVERTISING: '/advertising',
    ADVERTISING_POLICIES: '/advertising-policies',
    BUSINESS_MASTER_SERVICE_AGREEMENT: '/business-master-service-agreement',
  },
  HELP: {
    INDEX: HELP,
    GETTING_STARTED: `${HELP}/getting-started`,
    ACCOUNT: `${HELP}/account`,
    BILLING_AND_SUBSCRIPTIONS: `${HELP}/billing-and-subscriptions`,
    PRIVACY_SAFETY: `${HELP}/privacy-safety`,
    REPORT: `${HELP}/report`,
    INTELLECTUAL_PROPERTY: `${HELP}/intellectual-property`,
    DMCA: `${HELP}/dmca`,
  },
  SIDEBAR: {
    FEED: '/feed',
    DISCOVER: '/discover',
    FAVORITES: '/favorites',
    CREATED_BY_YOU: '/created-by-you',
    MESSAGE: '/messages',
  },
  CHAT: '/chat',
  EMBED: {
    INDEX: EMBED,
    PAYMENT_METHOD: `${EMBED}/payment-method`,
    LANDING: {
      RECHARGE: `${EMBED}/landing/recharge`,
      PLANS: `${EMBED}/landing/plans`,
    },
    RECHARGE: {
      BOOST: `${EMBED}/recharge/boost`,
      CHAT: `${EMBED}/recharge/chat`,
    },
    CONTACT_SALES: {
      INDEX: `${EMBED}/contact-sales`,
      COMPLETED: `${EMBED}/contact-sales/completed`,
    },
    WALLET: {
      CLOSE: `${EMBED}/wallet/close`,
      REDIRECT: `${EMBED}/wallet/redirect`,
    },
  },
  DEMO_PAL: '/chat-with-pals/:id',
}
